import React, {useState} from "react"
import Layout from "../components/Layouts/Layout/Layout"
import componentFactory from "../utils/componentFactory"
import LocataireBlock from "../components/Blocks/LocataireBlock/LocataireBlock"
import Seo from "../components/seo"
import TitleBlock from "../components/Blocks/TitleBlock/TitleBlock"

const StaticTemplate = ({ pageContext }) => {
  const page = pageContext.page.attributes
  const [displayNavBlock,setDisplayNavBlock]=useState(false);
  const components = page.components.map(i => componentFactory(i, pageContext))
  const noTitleBlockPages = ["/", "/assistance-locataire", "/recrutement", "/contact","/simulation"]
  return (
    <>
      <Layout displayNavBlock={displayNavBlock}>
        {page.url === "/"  && <LocataireBlock  setDisplayNavBlock={setDisplayNavBlock}/>}
        {(!noTitleBlockPages.includes(page.url)) && <TitleBlock title={pageContext.page.attributes.title} />}
        {components}
      </Layout>
    </>
  )
}
export const Head = ({ pageContext }) => (
  <Seo
    title={pageContext.page.attributes.title}
    description={pageContext.page.attributes.resume}
  />
)
export default StaticTemplate
