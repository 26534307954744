import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import * as locataireStyles from "./Locataire.module.scss"

const LocataireBlock = ({setDisplayNavBlock}) => {
  const scrollRef = useRef()
  const [scroll, setScroll] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 150)
    })
  }, [scrollRef])

  const CloseBlock = () => {
    scrollRef.current.remove();
    setDisplayNavBlock(true);

  }
  return (
    <>
      <div
        ref={scrollRef}
        className={
          scroll
            ? `${locataireStyles.locataireblock} ${locataireStyles.active}`
            : locataireStyles.locataireblock
        }
      >
        <button
          onClick={CloseBlock}
          className={
            scroll ? `${locataireStyles.close}` : locataireStyles.closeHide
          }
        >
          Fermer
          <svg
            width="18"
            height="18"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="13.8624"
              y1="14.496"
              x2="41.5045"
              y2="42.1382"
              stroke="#1e1f1d"
              strokeWidth="4"
            />
            <line
              x1="41.503"
              y1="13.1446"
              x2="13.8609"
              y2="40.7867"
              stroke="#1e1f1d"
              strokeWidth="4"
            />
          </svg>
        </button>
        <div className={locataireStyles.title}>
          <p className="fs-lg">Vous avez une question&nbsp;?</p>
        </div>
        <div className={locataireStyles.alignedContent}>
        <div>
          <Link
            to="/assistance-locataire"
            className={`${locataireStyles.linkbutton} ${"button secondary"}`}
            target="_blank"
            rel="noopener noreferrer"
          >
             Questions fréquentes
          </Link>
        </div>
        <Link
          to="https://app.pipefy.com/public/form/LbDssGkV"
          target="_blank"
          className={`${locataireStyles.link} ${"fs-md"}`}
          data-abbr="Besoin d’assistance&nbsp;?"
        >
          <span>Vous avez besoin d’assistance ?</span>
          <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5303 6.53033C12.8232 6.23744 12.8232 5.76256 12.5303 5.46967L7.75736 0.696699C7.46447 0.403806 6.98959 0.403806 6.6967 0.696699C6.40381 0.989593 6.40381 1.46447 6.6967 1.75736L10.9393 6L6.6967 10.2426C6.40381 10.5355 6.40381 11.0104 6.6967 11.3033C6.98959 11.5962 7.46447 11.5962 7.75736 11.3033L12.5303 6.53033ZM0 6.75L12 6.75V5.25L0 5.25L0 6.75Z"
              fill="white"
            />
          </svg>
        </Link>
        </div>
      </div>
    </>
  )
}
export default LocataireBlock
